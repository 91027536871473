import util from '@/util/helpers/policy-utils';
import PolicyApi from '@/util/api/policies';
import { convertToNumber } from '@/util/helpers/formatting';
import Zones from '@/util/api/zones';
import axios from 'axios';

const request = new PolicyApi();

export default {
  namespaced: true,
  state: {
    validating: false,
    validated: [],
    resetting: false,
    reset: [],
    sections: 0,
    saving: false,
    changes: [],
    destinations: [],
    statesData: {},
    settingsData: [],
    customizationsData: [],
    disableRefundProcessingEvents: false,
    zones: [],
  },
  mutations: {
    setCustomizationsData(state, { payload, lang = 'en' }) {
      state.customizationsData.content[lang] = payload;
    },
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace][key] = value;
      } else {
        state[key] = value;
      }
    },
    addChange(state, payload) {
      if (state.changes.find(item => item.key === payload.key && item.type === payload.type)) {
        state.changes = state.changes.map(item => {
          if (item.key === payload.key && item.type === payload.type) {
            return payload;
          }
          return item;
        });
      } else {
        state.changes = [
          ...state.changes,
          payload
        ];
      }
    },
    removeChange(state, payload) {
      state.changes = state.changes.filter(item => !(item.key === payload.key && item.type === payload.type));
    },
    setSettings(state, settings) {
      state.settingsData = settings
        .map(item => {
          return {
            ...item,
            pivot: {
              value:
                item.type === 'string'
                  ? item.pivot.value
                  : convertToNumber(item.pivot.value),
            }
          };
        });
    },
    updateSettings(state, settings) {
      state.settingsData = settings
        .map(item => {
          return {
            ...item,
            pivot: {
              value:
                item.type === 'string'
                  ? item.pivot.value
                  : convertToNumber(item.pivot.value),
            },
          };
        });
    },
    updateSection(state, payload) {
      state.sections = state.sections + payload;
    },
  },
  actions: {
    async getPageData({ commit, dispatch, rootState }/* , route */) {
      const res = await request.get();
      const zones = await Zones.getAll();
      await dispatch('settings/customizations/getContentData', null, { root: true });
      commit('setProperty', {
        key: 'destinations',
        value: res.data.destinations
      });
      commit('setProperty', {
        key: 'statesData',
        value: res.data.states
      });
      commit('setSettings', res.data.settings);
      commit('setProperty', {
        key: 'customizationsData',
        value: res.data.customizations
      });
      commit('setProperty', {
        key: 'disableRefundProcessingEvents',
        value: false
      });
      commit('setProperty', {
        key: 'zones',
        value: zones
      });
      if (rootState.userData && rootState.userData.shop && rootState.userData.shop.settings) {
        const updated = rootState.userData.shop.settings.map(item => {
          const newItem = res.data.settings.find(i => i.id === item.id);

          return newItem || item;
        });
        commit('setSettings', updated, { root: true });
      }
    },
    validated({ state, commit, dispatch }, item) {
      commit('setProperty', {
        key: 'validated',
        value: [
          ...state.validated,
          item
        ]
      });

      // If all sections have been accounted for, make sure
      // everything validated. state.validated is an array of true/false
      // values, true = valid, false = something wrong
      if (state.validated.length === state.sections) {
        if (state.validated.every(section => section === true)) {
          dispatch('save');
        } else {
          // Find the first error and scroll to it
          document.querySelector('.error')
            .scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          commit('setToast', {
            message: `Whoops, looks like a field wasn't filled out correctly`,
            type: 'error',
          }, { root: true });
        }
        commit('setProperty', { key: 'validated', value: [] });
        commit('setProperty', { key: 'validating', value: false });
      }
    },
    reset({ state, commit }, item) {
      commit('setProperty', {
        key: 'reset',
        value: [
          ...state.reset,
          item
        ]
      });

      // If all sections have been accounted for, update our
      // array that tracks the change requests and reset the reset
      // tracking array
      if (state.reset.length === state.sections) {
        commit('setProperty', {
          key: 'changes',
          value: []
        });

        commit('setProperty', {
          key: 'disableRefundProcessingEvents',
          value: false,
        });

        commit('setProperty', {
          key: 'reset',
          value: []
        });
        commit('setProperty', { key: 'resetting', value: false });
      }
    },
    async savePartial(_, { zone, payload }) {
      return axios.patch(`/return-policies/${zone.id}`, payload);
    },
    async save({ state, commit, dispatch }) {
      commit('setProperty', { key: 'saving', value: true });

      if (state.disableRefundProcessingEvents) {
        try {
          await dispatch('disableRefundProcessingEvents');
        } catch (e) {
          console.error(e);
          return;
        }
      }

      util.makeRequests(state.changes)
        .then(res => {
          const errors = res
            .filter(item => typeof item === 'object' && item.data && Array.isArray(item.data) && item.data.find(message => typeof message === 'object' && message.errors))
            .reduce((acc, item) => {
              return [
                ...acc,
                ...item.data
              ];
            }, [])
            .reduce((acc, item) => {
              if (typeof item === 'object' && item.errors) {
                return [
                  ...acc,
                  ...(Array.isArray(item.errors) ? item.errors : [item.errors])
                ];
              }

              return acc;
            }, []);

          dispatch('getPageData')
            .then(() => {
              const messaging = errors.length ? `Not all changes were saved: ${errors.join(', ')}` : `Changes saved`;
              commit('setProperty', { key: 'saving', value: false });
              commit('setProperty', {
                key: 'changes',
                value: []
              });
              commit('setToast', {
                message: messaging,
                type: errors.length ? 'error' : 'success',
              }, { root: true });
            });
        })
        .catch((error) => {
          console.error(error);

          commit('setToast', {
            message: 'Whoops, we ran into some issues saving these changes',
            type: 'error',
          }, { root: true });
          commit('setProperty', {
            key: 'changes',
            value: []
          });
        });
    },
    disableRefundProcessingEvents({ commit }) {
      return Zones.disableRefundEvents()
        .then(resp => {
          return resp.data;
        })
        .catch((err) => {
          commit('setToast', {
            message: 'There was an error disabling your refund processing events',
            type: 'error',
          }, { root: true });
          commit('setProperty', { key: 'saving', value: false });
          commit('setProperty', {
            key: 'changes',
            value: []
          });
          return Promise.reject(err);
        });
    }
  },
  getters: {
    hasChanges(state) {
      return state.changes.length > 0;
    }
  }
};
