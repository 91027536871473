<template>
  <div
    class="developers-skeleton"
    aria-hidden="true"
  >
    <skeleton-text
      class="developers-skeleton__heading"
      :lines="1"
      type="text-h4"
    />

    <page-layout align="right">
      <base-card class="developers-skeleton__card">
        <skeleton-text :lines="15" />
      </base-card>

      <template #sidebar>
        <base-card
          class="developers-skeleton__sidebar"
          type="unstyled"
        >
          <skeleton-text
            :lines="1"
            type="tabs"
          />

          <skeleton-text :lines="4" />
        </base-card>
      </template>
    </page-layout>

    <hr class="developers-skeleton__line">

    <page-layout align="right">
      <base-card class="developers-skeleton__card">
        <skeleton-text :lines="15" />
      </base-card>

      <template #sidebar>
        <base-card
          class="developers-skeleton__sidebar"
          type="unstyled"
        >
          <skeleton-text
            :lines="1"
            type="tabs"
          />

          <skeleton-text :lines="4" />
        </base-card>
      </template>
    </page-layout>
  </div>
</template>

<script>
import SkeletonText from '@/components/skeletons/SkeletonText';
import PageLayout from '@/components/structure/PageLayout';

export default {
  components: {
    SkeletonText,
    PageLayout,
  }
};
</script>

<style lang="scss" scoped>
$block: '.developers-skeleton';

#{$block} {
  width: 100%;

  &__heading {
    margin: 2rem 0;
  }

  &__card {
    margin-bottom: 1rem;
  }

  &__line {
    border: 0;
    border-top: 1px solid var(--color-neutral-200);
    margin: 1.25rem 0 2rem;
  }
}
</style>
