import store from '@/store';

export default [
  {
    path: '/testing-hub',
    component: () => import('@/pages/OnboardingHub/TestingHubPage'),
    name: 'TestingHub',
    meta: {
      layout: 'app',
      loader: false,
      title: 'Testing & Validation',
    },
    children: [
      {
        path: 'testing',
        name: 'TestingHubTesting',
        component: () => import('@/pages/OnboardingHub/Pages/SetupTesting'),
        beforeEnter: () => {
          if (!store.state.userData.has_onboarding_modules) {
            return { name: 'OnboardingHubBasicSetup' };
          }
        }
      },
    ]
  },
];
