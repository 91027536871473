<template>
  <transition name="fade-down">
    <div
      v-if="show"
      ref="modal"
      class="base-modal"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="base-modal__backdrop"
        @click="triggerClose"
      />

      <div class="base-modal__wrapper">
        <header
          v-if="showHeader"
          class="base-modal__header"
          :class="{'--center' : centerHeader, '--no-border': !showBorder }"
        >
          <slot name="header" />

          <div class="base-modal__close">
            <button
              class="base-modal__close-button"
              @click="triggerClose"
            >
              <span class="visually-hidden">Close modal</span>

              <svg
                v-if="showCloseButton"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </header>

        <main class="base-modal__body">
          <slot />
        </main>

        <footer
          v-if="showFooter"
          class="base-modal__footer"
          :class="{ '--no-border': !showBorder }"
        >
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default {
  name: 'BaseModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    centerHeader: {
      type: Boolean,
      default: false
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '900'
    }
  },
  emits: ['close', 'action'],
  data () {
    return {
      modalWidth: `${this.width}px`,
      focusableEls: [],
      focusedBeforeOpen: null,
    };
  },
  computed: {
    random() {
      // We need a unique number for making ids that don't clash
      return [...Array(16)]
        .map(() => (~~(Math.random() * 36)).toString(36))
        .join('');
    },
    firstFocusableEl() {
      return this.focusableEls.length > 0 ? this.focusableEls[0] : null;
    },
    lastFocusableEl() {
      return this.focusableEls.length > 0
        ? this.focusableEls[this.focusableEls.length - 1]
        : null;
    },
  },
  watch: {
    show(value) {
      this.watcher(value);
    },
    active(value) {
      this.watcher(value);
    },
  },
  mounted() {
    // We need to run this on initial mount for the initial
    // render, otherwise there's a case where handleOpen never gets
    // called because show never changes
    if (this.show || this.active) {
      this.handleOpen();
    }
  },
  unmounted() {
    enablePageScroll();
  },
  methods: {
    triggerClose() {
      /**
       *  When the user makes an action to close the modal (escape, click outside, close button). Use this to toggle the show prop.
       */
      this.$emit('close');
    },
    handleKeydown(e) {
      const handleBackwardTab = () => {
        if (document.activeElement === this.firstFocusableEl) {
          e.preventDefault();
          this.lastFocusableEl.focus();
        }
      };

      const handleForwardTab = () => {
        if (document.activeElement === this.lastFocusableEl) {
          e.preventDefault();
          this.firstFocusableEl.focus();
        }
      };

      switch (e.key) {
      case 'Tab':
        if (this.focusableEls.length === 1) {
          e.preventDefault();
          break;
        }
        if (e.shiftKey) {
          handleBackwardTab();
        } else {
          handleForwardTab();
        }
        break;
      case 'Escape':
        this.triggerClose();
        break;
      default:
        break;
      }
    },
    handleOpen() {
      disablePageScroll();

      this.$nextTick(() => {
        // Selectors to be used for getting all elements that are focusable
        const selectors =
          'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';

        // Cache previously focused element
        this.focusedBeforeOpen = document.activeElement;

        // Get a list of all focusable elements in this modal
        if (this.$refs.modal) {
          this.focusableEls = [...this.$refs.modal.querySelectorAll(selectors)];

          // Focus the first focusable element
          this.focusableEls[0].focus();

          // Add event listener for tab/shift + tab/escape
          this.$refs.modal.addEventListener('keydown', this.handleKeydown);
        }

        // Add global keydown event listener
        window.addEventListener('keydown', this.handleKeydown);
      });
    },
    handleClose() {
      // Reset data back to default
      this.focusedBeforeOpen = null;
      this.focusableEls = [];

      // Clean up listeners, so we don't have tons of orphaned
      // event listeners taking up all the ram
      if (this.$refs.modal) {
        this.$refs.modal.removeEventListener('keydown', this.handleKeydown);
      }

      // Remove global keydown event listener
      window.removeEventListener('keydown', this.handleKeydown);

      // Switch focus back to previously focused element
      if (this.focusedBeforeOpen) {
        this.focusedBeforeOpen.focus();
      }

      enablePageScroll();
    },
    watcher(value) {
      // Handle setup and teardown. Since the v-if is inside this
      // component, lifecycle hooks aren't getting called like you would
      // expect. To solve this we would need to put the v-if outside this
      // modal, which seems clunky
      this.$nextTick(() => {
        if (value) {
          this.handleOpen();
        } else {
          this.handleClose();
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>
$block: '.base-modal';

#{$block} {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
  backface-visibility: hidden;

  &__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 10px;
    box-shadow: var(--modal-shadow);
    background-color: var(--color-white, #fff);
    width: 100%;
    max-width: v-bind(modalWidth);
    height: 100%;
    max-height: 700px;
    display: flex;
    flex-direction: column;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(99, 115, 129, 70%);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-300) var(--spacing-400);
    position: relative;
    border-bottom: 1px solid var(--color-neutral-200);

    &.--center {
      justify-content: center;
    }
  }

  &__body {
    flex-grow: 3;
  }

  &__close {
    position: absolute;
    right: 24px;
    top: 16px;
    cursor: pointer;
    align-self: flex-end;
    z-index: 9999;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      transition: background-color 0.2s ease-in-out;
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: auto 1fr auto;
    border-top: 1px solid var(--color-neutral-200);
    padding: var(--spacing-300) var(--spacing-400);
    z-index: 9999;
  }

  .--no-border {
    border: none;
  }
}
</style>

<style>
.fade-down-enter-active,
.fade-down-leave-active {
  transition: opacity var(--transition-300), transform var(--transition-300);
}

.fade-down-enter,
.fade-down-leave-to {
  transform: translate3d(0, -6px, 0);
  opacity: 0;
}
</style>
