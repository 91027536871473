<template>
  <!--
    Icons default to 1.5rem. To customize just apply a class to the <svg-icon> and add a height/width there.
  -->
  <span
    v-if="icon"
    class="icon"
  >
    <span class="visually-hidden">{{ displayText }}</span>

    <span
      class="icon__content"
      :class="[{ animate }, name]"
      v-html="icon"
    />
  </span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false,
    },
    animate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      modules: null,
    };
  },
  computed: {
    icon() {
      //return the html from the module array that matches this.name
      for (const key in this.modules) {
        const iconName = key.replace('../assets/icons/', '').replace('.svg', '');
        //if the name of the icon matches the name prop, return the icon
        if (iconName === this.name) {
          return this.modules[key];
        }
      }
      return null;

    },
    displayText() {
      return this.text ? this.text : `${this.name} icon`;
    }
  },
  created() {
    this.modules = import.meta.glob('../assets/icons/**/*.svg', {
      as: 'raw',
      eager: true,
    });
  }
};

</script>

<style lang="scss">
@keyframes checkmark {
  0% {
    stroke-dashoffset: 17px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  color: inherit;

  svg {
    color: inherit;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  &__content {
    color: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .animate {
    &.checkmark {
      stroke-dasharray: 17px;

      .checkmark-path {
        animation: checkmark var(--timing-500) ease-in-out forwards;
      }
    }
  }
}
</style>
