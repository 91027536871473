import Locations from '@/util/api/locations';

export default {
  namespaced: true,
  state: {
    locations: [],
  },
  getters: {
    locations: state => state.locations,
  },  
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    setLocations(state, value) {
      state.locations = value;
    },
  },
  actions: {
    async getAll({ commit }) {
      try {
        const locations = await Locations.getAllAvailable();
        commit('setLocations', locations);
        return Promise.resolve(locations);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getAllForFulfillability({ commit }) {
      try {
        const locations = await Locations.getAllAvailableForFulfillability();
        commit('setLocations', locations);
        return Promise.resolve(locations);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateFulfillableLocations({ commit }, ids) {
      try {
        const locations = await Locations.updateFulfillableLocations(ids);
        commit('setLocations', locations);
        return Promise.resolve(locations);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }
};
