<template>
  <transition name="settings-container">
    <div
      v-if="barActive"
      class="settings-bar"
      :style="position"
    >
      <div class="settings-bar__wrap">
        <div class="settings-bar__content">
          <p class="heading subdued-text">
            Unsaved changes
          </p>

          <div class="settings-bar__button__container">
            <styled-button
              v-if="reset"
              type="outline"
              class="settings-bar__button"
              @click="reset()"
            >
              Cancel
            </styled-button>

            <styled-button
              class="settings-bar__button"
              :activated="savingActive === true || saving"
              @click="saveChanges()"
            >
              Save
            </styled-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable vue/require-prop-types */
export default {
  props: [
    'barActive',
    'save',
    'reset',
    'saving',
    'bottom'
  ],
  data: function () {
    return {
      savingActive: false
    };
  },
  computed: {
    position() {
      return this.bottom ? { bottom: 0 } : { top: 0 };
    }
  },
  mounted: function() {
    this.savingActive = false;
  },
  methods: {
    saveChanges: function() {
      this.savingActive = true;
      this.save();
      this.savingActive = false;
      // that.reset();
      // that.barActive = false;
    }
  }
};

</script>

<style lang="scss">
.settings {
  &-container {
    &-enter-active {
      animation: fadeIn 0.3s;
    }

    &-leave-active {
      transform: translateY(-100%);
    }
  }

  &-bar {
    background-color: white;
    position: fixed;
    height: 3.5rem;
    right: 0;
    padding: 0;
    width: calc(100% - 15rem);
    z-index: 1010;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-default);
    animation-fill-mode: forwards;
    transition: transform var(--transition-200);

    &__bottom {
      bottom: 0;
    }

    h3 {
      padding-left: 2rem;
    }

    &__cover {
      width: var(--nav-width);
      border-right: 1px solid var(--color-neutral-200);
      display: flex;
      justify-content: flex-start;
      padding: 1rem 1rem 1rem 0.9rem;
      align-items: center;
      height: 100%;
      flex-shrink: 0;
    }

    &__logo {
      width: 4.5rem;
      padding-top: 0.5rem;
      filter: invert(0.4);
    }

    &__wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 100%;
    }

    &__content {
      width: 100%;
      max-width: 1080px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.25rem 2rem;
    }

    &__button {
      &:not(:last-of-type) {
        margin-right: 0.75rem;
      }

      &__container {
        margin-left: auto;
        display: flex;
      }
    }
  }

  &-container {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-neutral-200);
    justify-content: center;
    align-items: flex-start;

    &__info {
      width: 30%;

      a {
        font-size: 0.75rem;
        display: block;

        &.ep {
          margin-top: 0.5rem;
        }
      }

      h3 {
        padding-bottom: 1rem;
      }

      p {
        color: var(--color-neutral-500);
        padding-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: $break-medium) {
  .settings {
    &-bar {
      width: 100%;
    }
  }
}

</style>
