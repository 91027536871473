import shipByLoopWorldWide, { ShipByLoopWorldwideConfig } from '@/util/api/shipByLoopWorldWide';
import {
  AccountSummaryData,
  Carrier,
  CarrierShop,
  Credit,
  PaymentSource,
  PendingCarrierAccount,
} from '@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/Types';
import { Commit, Dispatch } from 'vuex';

type Nullable<T> = T | null;

interface CardInfo {
  last4: string;
  brand: string;
  expirationMonth: string;
  expirationYear: string;
}

interface SBL {
  // catch-all for any keys that are currently unknown
  [key: string]: any;
  credit: Credit;
  paymentSources: PaymentSource[];
  payment_method_type: string;
  hasSelectedCarriers: boolean;
  easyshipDefaultCategory: Nullable<string>;
  isOnboarding?: boolean;
}

interface ShipByLoopWorldwideState {
  shippingSettingId: string | null;
  sbl: Nullable<SBL>;
  newEmail: string | null;
  showByoaForm: boolean;
  showByoaAccount: number | undefined;
  byoaFormData: object;
  refreshPayment: boolean;
  stripe: any;
  stripeElements: any;
  error: any;
  cardInfo: CardInfo | null;
  easyshipPaymentSources: [string] | null;
  carriers: Carrier[];
  carrierAccounts: CarrierShop[];
  pendingCarrierAccounts: PendingCarrierAccount[];
  wantsCarriers: boolean;
  wantsPaymentSource: boolean;
}

export interface BillingAddress {
  name: string;
  streetAddress: string;
  aptNumber: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface RechargeAmount {
  rechargeAmount: number;
}

interface UpdateConfigRequest {
  stripeTokenId?: string;
  deletingPaymentSource?: string;
  updatingDefaultPaymentSource?: string;
  defaultCategory?: string;
}

const state: ShipByLoopWorldwideState = {
  shippingSettingId: null,
  sbl: null,
  newEmail: null,
  showByoaForm: false,
  showByoaAccount: undefined,
  byoaFormData: {},
  refreshPayment: false,
  stripe: null,
  stripeElements: null,
  error: null,
  cardInfo: null,
  easyshipPaymentSources: null,
  wantsCarriers: false,
  carriers: [],
  carrierAccounts: [],
  pendingCarrierAccounts: [],
  wantsPaymentSource: false,
};

export default {
  namespaced: true,
  state,
  mutations: {
    setShippingSettingId(state: ShipByLoopWorldwideState, id: string) {
      state.shippingSettingId = id;
    },
    setSbl(state: ShipByLoopWorldwideState, sbl: any) {
      state.sbl = sbl;
    },
    setPartialSbl(state: ShipByLoopWorldwideState, partialSbl: any) {
      state.sbl = {
        ...state.sbl,
        ...partialSbl,
      };
    },
    setNewEmail(state: ShipByLoopWorldwideState, newEmail: string) {
      state.newEmail = newEmail;
    },
    setShowByoaAccount(state: ShipByLoopWorldwideState, showByoaAccount: number | undefined) {
      state.showByoaAccount = showByoaAccount;
    },
    setShowByoaForm(state: ShipByLoopWorldwideState, showByoaForm: boolean) {
      state.showByoaForm = showByoaForm;
    },
    setByoaFormData(state: ShipByLoopWorldwideState, byoaFormData: object) {
      state.byoaFormData = byoaFormData;
    },
    setRefreshPayment(state: ShipByLoopWorldwideState, refreshPayment: boolean) {
      state.refreshPayment = refreshPayment;
    },
    setStripe(state: ShipByLoopWorldwideState, stripe: any) {
      state.stripe = stripe;
    },
    setStripeElements(state: ShipByLoopWorldwideState, stripeElements: any) {
      state.stripeElements = stripeElements;
    },
    setError(state: ShipByLoopWorldwideState, error: any) {
      state.error = error;
    },
    setCarriers(state: ShipByLoopWorldwideState, carriers: Carrier[]) {
      state.carriers = carriers;
    },
    setWantsCarriers(state: ShipByLoopWorldwideState, wantsCarriers: boolean) {
      state.wantsCarriers = wantsCarriers;
    },
    setCarrierAccounts(state: ShipByLoopWorldwideState, carrierAccounts: CarrierShop[]) {
      state.carrierAccounts = carrierAccounts;
    },
    setPendingCarrierAccounts(state: ShipByLoopWorldwideState, pendingCarrierAccounts: PendingCarrierAccount[]) {
      state.pendingCarrierAccounts = pendingCarrierAccounts;
    },
    setCredit(state: ShipByLoopWorldwideState, credit: Credit) {
      if (state.sbl?.credit) {
        state.sbl.credit = credit;
      }
    },
    setWantsPaymentSource(state: ShipByLoopWorldwideState, wantsPaymentSource: boolean) {
      state.wantsPaymentSource = wantsPaymentSource;
    },
  },
  actions: {
    async connect({ state, commit }: { state: ShipByLoopWorldwideState, commit: any}) {
      commit('setError', null);
      commit('settings/shipByLoop/setIsConnecting', true, { root: true });

      const sblConfig: ShipByLoopWorldwideConfig = {
        email: state.sbl?.email,
        name: state.sbl?.accountName,
      };

      await shipByLoopWorldWide.postConfig(sblConfig)
        .then((response) => {
          const sblInfo = response.data;
          sblInfo.isOnboarding = state.sbl?.isOnboarding !== false || sblInfo.isOnboarding;
          commit('setSbl', sblInfo);
          commit('settings/shipByLoop/setIsNewIntegration', false, { root: true });
          commit('setShippingSettingId', response.data.id);
        })
        .catch((error: any) => {
          commit('setError', {
            message: error.response.data.details,
            error: error.response,
          });
        });

      commit('settings/shipByLoop/setIsConnecting', false, { root: true });
    },
    async getConfig({ state, commit }: { state: ShipByLoopWorldwideState, commit: Commit }) {
      commit('setError', null);
      if (!state.shippingSettingId) {
        commit('setError', {
          message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
        });
        return;
      }

      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      try {
        const { data: sblInfo } = await shipByLoopWorldWide.getConfig(state.shippingSettingId);
        commit('settings/shipByLoop/setIsNewIntegration', false, { root: true });
        sblInfo.isOnboarding = state.sbl?.isOnboarding === true || sblInfo.isOnboarding;
        commit('setSbl', sblInfo);
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to retrieve the Ship by Loop Worldwide configuration. Please navigate back to the Shipping page and try again.',
          error: error.response,
        });
      }

      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
    async updateConfig({ state, commit }: { state: ShipByLoopWorldwideState, commit: any }, updateConfigRequest: UpdateConfigRequest) {
      commit('setError', null);
      if (!state.sbl) {
        commit('setError', {
          message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
        });
      }

      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      let payload: any = { email: state.newEmail ?? state.sbl.email };
      if (updateConfigRequest.stripeTokenId) {
        payload.primaryPaymentToken = updateConfigRequest.stripeTokenId;
      }
      if (updateConfigRequest.updatingDefaultPaymentSource) {
        payload.updatingDefaultPaymentSource = updateConfigRequest.updatingDefaultPaymentSource;
      }
      if (updateConfigRequest.deletingPaymentSource) {
        payload.deletingPaymentSource = updateConfigRequest.deletingPaymentSource;
      }
      if (updateConfigRequest.defaultCategory) {
        payload.defaultCategory = updateConfigRequest.defaultCategory;
      }

      try {
        const { data: res } = await shipByLoopWorldWide.patchConfig(state.sbl.id, payload);
        res.isOnboarding = state.sbl?.isOnboarding;

        commit('setSbl', res);
        commit('setError', null);
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to update the Ship by Loop Worldwide configuration. Please navigate back to the Shipping page and try again.',
          error: error.response,
        });
      }
    },
    async deleteConfig({ state, commit }: { state: ShipByLoopWorldwideState, commit: any }) {
      commit('setError', null);
      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      try {
        await shipByLoopWorldWide.deleteConfig(state.sbl.id);
        commit('setSbl', null);
        commit('settings/shipByLoop/setIsNewIntegration', true, { root: true });
        commit('setNewEmail', null);
        commit('setError', null);
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to update the Ship by Loop Worldwide configuration. Please navigate back to the Shipping page and try again.',
          error: error.response,
        });
      }

      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
    setUpStripe({ state, commit }: { state: ShipByLoopWorldwideState, commit: any }) {
      let stripe, elements;
      try {
        stripe = Stripe(state.sbl?.stripePublicKey);
        elements = stripe.elements();
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to connect with Stripe. Please navigate back to the Shipping page and try again.',
          error: error.response,
        });
      }

      commit('setStripe', stripe);
      commit('setStripeElements', elements);
    },
    updateCompanyAddress({ state }: { state: ShipByLoopWorldwideState }, { billingInfo }: { billingInfo: BillingAddress }) {
      if (state.shippingSettingId) {
        return shipByLoopWorldWide.updateBillingAddress(state.shippingSettingId, billingInfo);
      }
    },
    async getStripeToken({ state, commit, dispatch }: { state: ShipByLoopWorldwideState, commit: any, dispatch: any }, { billingInfo }: { billingInfo: BillingAddress }) {
      commit('setError', null);
      const card = state.stripeElements.getElement('card');

      const res = await state.stripe.createToken(card, {
        name: billingInfo.name,
        address_line1: billingInfo.streetAddress ?? '',
        address_line2: billingInfo.aptNumber ?? '',
        address_city: billingInfo.city ?? '',
        address_state: billingInfo.state ?? '',
        address_zip: billingInfo.zip ?? '',
        address_country: billingInfo.country ?? '',
      });

      if (!res.error) {
        await dispatch('updateConfig', { stripeTokenId: res.token.id });
      } else {
        console.error(res.error);
        commit('setError', {
          message: 'Check your credit card information and try submitting again. If the problem persists please contact support@loopreturns.com.',
          error: res.error.response,
        });
      }
    },
    async recharge({ state, commit, dispatch }: { state: ShipByLoopWorldwideState, commit: Commit, dispatch: Dispatch }, { rechargeAmount }: { rechargeAmount: RechargeAmount }) {
      commit('setError', null);
      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      if (!state.shippingSettingId) {
        commit('setError', {
          message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
        });
        return;
      }

      if (!state.stripe) {
        await dispatch('setUpStripe');
      }

      let response;

      try {
        response = await shipByLoopWorldWide.recharge(state.shippingSettingId, {
          paymentSourceId: state.sbl.easyshipPaymentSources[0],
          amount: rechargeAmount.rechargeAmount,
        });
        commit('setCredit', response.data.credit);
      } catch (error: any) {
        console.error(error);
        const hasMessages = error.response?.data?.message;
        commit('setError', {
          message: (hasMessages)
            ? error.response.data.message
            : 'We were unable to apply the charge to your payment method. If the problem persists please contact support@loopreturns.com.',
          error: error.response,
        });
      }

      while (response?.status === 202) { // TO DO: check for status 202 to initiate 3DS
        try {
          await state.stripe.handleCardAction(response?.data.action_required.client_secret);
          response = await shipByLoopWorldWide.confirmRecharge(state.shippingSettingId, { intentId: response?.data.action_required.intent_id });
          commit('setCredit', response.data.credit);
          commit('setWantsCarriers', true);
        } catch (error: any) {
          console.error(error);
          const hasMessages = error.response?.data?.message;
          commit('setError', {
            message: (hasMessages)
              ? error.response.data.message
              : 'We were unable to apply the charge to your payment method. If the problem persists please contact support@loopreturns.com.',
            error: error.response,
          });
          break;
        }
      }

      if (response?.status === 201) {
        // 3DS verification successful!
      }
      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
    async carrierAccounts({ state, commit }: { state: ShipByLoopWorldwideState, commit: any }) {
      if (!state.shippingSettingId) {
        commit('setError', {
          message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
        });
        return;
      }

      let response;
      try {
        response = await shipByLoopWorldWide.getCarrierAccounts();
        commit('setCarrierAccounts', response.data);

        return response.data;
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to get carriers for Ship By Loop Worldwide',
          error: error.response,
        });
      }
    },
    async addCarrierAccount({ state, commit }: { state: ShipByLoopWorldwideState, commit: any },
      { carrierId, carrier = 'easyship', isByoa = false }:
      { carrierId: string|number, carrier: string, isByoa: boolean }) {
      // Reset errors to prevent them persisting through "sessions"
      commit('setError', null);

      if (!state.shippingSettingId && !isByoa) {
        commit('setError', {
          message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
        });
        return;
      }

      let response;

      try {
        await shipByLoopWorldWide.addCarrierAccount({ carrierId: carrierId, carrier: carrier, formData: state.byoaFormData })
          .then(() => {
            let data = { ...state.byoaFormData };
            delete data.password;
            delete data.apiKey;

            commit('setByoaFormData', data);
          });
        response = await shipByLoopWorldWide.getCarrierAccounts();

        commit('setCarrierAccounts', response.data);

        return response.data;
      } catch (error: any) {
        console.error(error);

        if (isByoa) {
          if (error.response.status === 422) {
            commit('setError', {
              description: Object.values(error.response?.data?.errors).flat(),
            });
          } else if (error?.details) {
            commit('setError', {
              description: error.details
            });
          }
        } else {
          commit('setError', {
            message: 'Unable to update carriers',
            description: error.response,
          });
        }
      }
    },
    async deleteCarrierAccount({ state, commit }: { state: ShipByLoopWorldwideState, commit: any }, carrierShopId: string) {
      if (!state.shippingSettingId) {
        commit('setError', {
          message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
        });
        return;
      }

      let response;
      try {
        await shipByLoopWorldWide.deleteCarrierAccount(carrierShopId);
        response = await shipByLoopWorldWide.getCarrierAccounts();
        commit('setCarrierAccounts', response.data);

        return response.data;
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to update carriers',
          error: error.response,
        });
      }
    },
    async carriers({ state, commit }: { state: ShipByLoopWorldwideState, commit: any }) {
      if (!state.shippingSettingId || !state.sbl?.integrationId) {
        commit('setError', {
          message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
        });
        return;
      }

      let response;
      try {
        response = await shipByLoopWorldWide.getCarriers(state.sbl.integrationId);
        commit('setCarriers', response.data);

        return response.data;
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to get carriers for Ship By Loop Worldwide',
          error: error.response,
        });
      }
    },
    async pendingCarriers({ commit }: { state: ShipByLoopWorldwideState, commit: any }) {
      let response;
      try {
        response = await shipByLoopWorldWide.getPendingCarrierAccounts();
        commit('setPendingCarrierAccounts', response.data);

        return response.data;
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to get pending carrier accounts for Ship By Loop Worldwide',
          error: error.response,
        });
      }
    },
    async accountSummary({ state, commit }: { state: ShipByLoopWorldwideState, commit: any }) {
      try {
        if (!state.shippingSettingId) {
          commit('setError', {
            message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
          });
          return;
        }

        if (!state.carrierAccounts?.length) {
          const response = await shipByLoopWorldWide.getCarrierAccounts();
          commit('setCarrierAccounts', response.data);
        }
        if (!state.sbl?.credit || !state.sbl?.paymentSources) {
          const { data: sblInfo } = await shipByLoopWorldWide.getConfig(state.shippingSettingId);
          commit('setSbl', sblInfo);
        }
      } catch (error: any) {
        console.error(error);
        commit('setError', {
          message: 'Unable to fetch account summary. If the problem persists, please contact support@loopreturns.com.',
          error: error.response
        });
      }
    },
  },
  getters: {
    sbl: (state: ShipByLoopWorldwideState) => state.sbl,
    newEmail: (state: ShipByLoopWorldwideState) => state.newEmail,
    showByoaForm: (state: ShipByLoopWorldwideState) => state.showByoaForm,
    showByoaAccount: (state: ShipByLoopWorldwideState) => state.showByoaAccount,
    byoaFormData: (state: ShipByLoopWorldwideState) => state.byoaFormData,
    refreshPayment: (state: ShipByLoopWorldwideState) => state.refreshPayment,
    stripe: (state: ShipByLoopWorldwideState) => state.stripe,
    stripeElements: (state: ShipByLoopWorldwideState) => state.stripeElements,
    error: (state: ShipByLoopWorldwideState) => state.error,
    cardInfo: (state: ShipByLoopWorldwideState) => state.cardInfo,
    carriers: (state: ShipByLoopWorldwideState) => state.carriers,
    carrierAccounts: (state: ShipByLoopWorldwideState) => state.carrierAccounts,
    pendingCarrierAccounts: (state: ShipByLoopWorldwideState) => state.pendingCarrierAccounts,
    wantsPaymentSource: (state: ShipByLoopWorldwideState) => state.wantsPaymentSource,
    wantsCarriers: (state: ShipByLoopWorldwideState) => state.wantsCarriers,
    accountSummary: (state: ShipByLoopWorldwideState): AccountSummaryData => ({
      carrierAccounts: state.carrierAccounts,
      paymentMethod: state.sbl?.paymentSources?.length ? state.sbl?.paymentSources[0]?.card.last_four_digits : undefined,
      credit: state.sbl?.credit,
    }),
    isOnboarding: (state: ShipByLoopWorldwideState) => state.sbl?.isOnboarding,
  },
};
