<template>
  <div class="loading-page">
    <component
      :is="skeleton"
      v-if="skeleton"
    />

    <div
      v-else-if="hourglass"
      class="loading-page__hourglass"
    >
      <img
        src="/img/hourglass.gif"
        alt="Hourglass"
      />
    </div>

    <base-spinner
      v-else
      class="loading-page__spinner"
      :active="true"
      type="loop"
    />
  </div>
</template>

<script>
import BaseSpinner from '@/components/feedback/BaseSpinner';
import { identify, track } from '@/util/helpers/segment';
import { trackingEvents } from '@/constants/segment';

export default {
  name: 'LoadingPage',
  components: {
    BaseSpinner,
  },
  props: {
    loader: {
      type: [Boolean, Function],
      required: false,
      default: false
    },
    hourglass: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    skeleton() {
      if (this.loader && typeof this.loader === 'function') {
        return this.loader();
      }

      return null;
    }
  },
  mounted() {
    if (this.$route.name === 'Login Callback' && this.$store.state.userData) {
      identify(this.$store.state.userData, this.$store.state.userData.shop);
      track(trackingEvents.USER_LOGIN, {
        user: this.$store.state.userData,
        shop_id: this.$store.state.userData.shop_id,
      });
    }
  },
};
</script>


<style lang="scss" scoped>
.loading-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__hourglass {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    img {
      width: 145px;
      height: 160px;
    }
  }

  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
</style>
