<template>
  <div class="navigation-header-search">
    <div class="navigation-header-search__input-wrapper">
      <svg-icon
        name="search-global"
        class="navigation-header-search__icon"
      />

      <base-input
        v-model.trim="query"
        placeholder="Search"
        class="navigation-header-search__input"
        aria-label="Global search"
        @focus="showResults = true"
        @blur="handleBlur"
        @keydown="handleSearchKeydown"
      />

      <base-button
        v-if="query !== ''"
        class="navigation-header-search__clear"
        type="icon"
        icon="close"
        @click="clearSearch"
        @blur="handleBlur"
      >
        <span class="visually-hidden">Clear search</span>
      </base-button>

      <div
        v-else
        class="navigation-header-search__shortcut"
      >
        <p>
          <span v-if="isMac">⌘</span>

          <span v-else>Ctrl + </span>
          K
        </p>
      </div>
    </div>

    <div
      v-show="showResults"
      class="navigation-header-search__results-wrapper"
    >
      <div class="navigation-header-search__results-wrapper-content">
        <span
          v-if="filteredResults.length === 0"
          class="navigation-header-search__result-item no-results"
        >
          <p class="navigation-header-search__result-item-name text-body-semibold">No results</p>

          <p class="navigation-header-search__result-item-description">
            Oops! We couldn't find what you're looking for. Try using our admin navigation in the sidebar or check our <a
              href="https://help.loopreturns.com/"
              class="navigation-header-search__link"
              target="_blank"
            >Help Center</a> for further assistance.</p>
        </span>

        <button
          v-for="result in filteredResults"
          :key="result.name"
          class="navigation-header-search__result-item"
          :class="{ 'is-active': selectedResult?.name === result.name }"
          @focus="showResults = true"
          @blur="handleBlur"
          @keydown="handleResultKeydown"
          @click="handleResultClick(result)"
        >
          <span class="navigation-header-search__result-item-name text-body-semibold">
            {{ result.name }}
          </span>

          <span class="navigation-header-search__result-item-description">
            {{ result.description }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BaseButton } from '@loophq/ui';
import { BaseInput } from '@loophq/component-library';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { results } from '@/constants/globalHeaderSearchResults';
import router from '@/router';
import { track } from '@/util/helpers/segment';
import { trackingEvents } from '@/constants/segment';

const query = ref('');
const showResults = ref(false);
const selectedResult = ref(null);
const resultIndex = ref(-1);

const handleKeydown = (e) => {
  if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    document.querySelector('.navigation-header-search__input')?.focus();
  }
};

onMounted(() => {
  window?.addEventListener('keydown', handleKeydown);
});

onUnmounted(() => {
  window?.removeEventListener('keydown', handleKeydown);
});

watch(query, (newQuery, oldQuery) => {
  if (newQuery !== oldQuery) {
    resetSelectedResult();

    if (newQuery !== '') {
      showResults.value = true;
    }
  }
});

const filteredResults = computed(() => {
  const searchTerm = query.value.toLowerCase();
  if (searchTerm === '') {
    return results.filter(item => item.isVisible && item.order === 1).slice(0, 5);
  }
  return results
    .filter(item => 
      item.isVisible &&
      (item.name.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm))
    )
    .sort((a, b) => a.order - b.order);
});

const isMac = computed(() => {
  return navigator?.userAgentData?.platform?.indexOf('mac') > -1;
});

const clearSearch = () => {
  query.value = '';
};

const resetSelectedResult = () => {
  selectedResult.value = null;
  resultIndex.value = -1;
};

const enableEscapeKey = (e) => {
  if (e.key === 'Escape') {
    showResults.value = false;
  }
};

const handleBlur = () => {
  setTimeout(() => {
    const activeElements = document.activeElement?.classList;

    // don't hide results if the user is interacting with search elements
    const interactiveSearchElements = ['navigation-header-search__result-item', 'navigation-header-search__clear', 'navigation-header-search__link'];
    if (!interactiveSearchElements.some(className => activeElements.contains(className))) {
      showResults.value = false;
    }
  }, 100);
};

// add accessibility and keyboard navigation support
const handleSearchKeydown = (e) => {
  enableEscapeKey(e);

  if (e.key === 'ArrowDown') {
    e.preventDefault();
    if (filteredResults.value.length > 0 && filteredResults.value[resultIndex.value + 1]) {
      resultIndex.value += 1;
      selectedResult.value = filteredResults.value[resultIndex.value];

      // Scroll the newly selected result into view
      const resultElements = document.querySelectorAll('.navigation-header-search__result-item');
      if (resultElements[resultIndex.value]) {
        resultElements[resultIndex.value].scrollIntoView({
          block: 'nearest', // Scroll to the nearest part of the container
          behavior: 'smooth', // Smooth scrolling animation
        });
      }
    }
  }

  if (e.key === 'ArrowUp') {
    e.preventDefault();
    if (filteredResults.value.length > 0 && filteredResults.value[resultIndex.value - 1]) {
      resultIndex.value -= 1;
      selectedResult.value = filteredResults.value[resultIndex.value];

      // Scroll the newly selected result into view
      const resultElements = document.querySelectorAll('.navigation-header-search__result-item');
      if (resultElements[resultIndex.value]) {
        resultElements[resultIndex.value].scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }
  }

  if (e.key === 'Enter') {
    if (selectedResult.value) {
      router.push(selectedResult.value.url);
      document.querySelector('.navigation-header-search__input')?.blur();
    }
  }
};

const handleResultKeydown = (e) => {
  enableEscapeKey(e);
};

const handleResultClick  = (result) => {
  track(trackingEvents.GLOBAL_SEARCH_RESULT_CLICKED, {
    query: query.value,
    page: result.url,
  });

  router.push(result.url);
  showResults.value = false;
};
</script>

<style lang="scss" scoped>
$block: '.navigation-header-search';

#{$block} {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  margin: 0 var(--spacing-8);

  &__input-wrapper {
    position: relative;
    width: 100%;
    max-width: 580px;
  }

  &__icon {
    position: absolute;
    left: var(--spacing-12);
    top: var(--spacing-8);
    width: 20px;
    height: 20px;
    z-index: 1;
  }

  &__shortcut {
    visibility: hidden;
    position: absolute;
    right: var(--spacing-16);
    top: var(--spacing-8);
    color: var(--color-neutral-100);
    background: var(--color-neutral-900);
    padding: 2px var(--spacing-4);
    border-radius: var(--spacing-4);
    border: 1px solid var(--color-neutral-500);

    @media screen and (width >= 960px) {
      visibility: visible;
    }

    p {
      font-size: 0.75rem;
    }
  }

  &__clear {
    position: absolute;
    right: var(--spacing-12);
    top: var(--spacing-8);
    color: var(--color-neutral-100) !important;
    z-index: 1;
  }

  &__input {
    width: 100%;
    padding-left: 40px !important;
    border-radius: 10px !important;
    border: 1px solid var(--color-neutral-700) !important;
    background: var(--color-neutral-900) !important;
    color: var(--color-neutral-100) !important;
  }

  &__results-wrapper {
    position: absolute;
    top: calc(100% + 12px);
    width: 100%;
    max-width: 580px;
    border-radius: 10px !important;
    padding: 0 0 0 var(--spacing-300);
    background: var(--color-neutral-900);
    border: 1px solid var(--color-neutral-700);
    max-height: 400px; /* This limits the height of the wrapper */
    overflow: hidden; /* Ensure content outside the bounds is hidden */
    display: flex;
    flex-direction: column;
  }

  &__results-wrapper-content {
    flex-grow: 1; /* Allow this container to grow and take up available space */
    overflow-y: auto; /* Enable vertical scrolling when content exceeds the parent's height */
    width: 100%;
    padding: var(--spacing-200) var(--spacing-100) var(--spacing-200) 0; /* Optional padding inside the scrollable area */
  }

  &__results-wrapper-content::-webkit-scrollbar {
    width: 12px;
  }

  &__results-wrapper-content::-webkit-scrollbar-track {
    background: var(--color-neutral-900);
  }

  &__results-wrapper-content::-webkit-scrollbar-thumb {
    background: var(--color-neutral-700);
    border-radius: 10px;
  }

  &__results-wrapper-content::-webkit-scrollbar-thumb:hover {
    background: var(--color-neutral-700);
  }

  &__result-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--spacing-200);
    text-align: left;

    @media screen and (width >= 960px) {
      padding: var(--spacing-200) var(--spacing-400);
    }

    &:hover,
    &.is-active {
      background: var(--color-neutral-700);
    }

    &.focus-visible {
      outline: auto;
    }

    &.no-results {
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      padding: var(--spacing-24);

      .navigation-header-search__result-item-name {
        margin-bottom: var(--spacing-8);
      }

      .navigation-header-search__result-item-description {
        font-size: 0.875rem;
      }

      &:hover,
      &.is-active {
        background: transparent;
      }

      @media screen and (width >= 960px) {
        padding: var(--spacing-64);
      }
    }
  }

  &__result-item-name {
    font-size: 1rem;
    color: var(--color-neutral-100);
  }

  &__result-item-description {
    font-size: 0.75rem;
    color: var(--color-neutral-100);
  }

  &__link {
    color: var(--color-neutral-100);
    text-decoration: underline;
  }
}
</style>
